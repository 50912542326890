<template>
  <div id="cesiumContainer">
    <template v-if="viewerInitialized">
      <v-btn class="logo" plain href="https://map.ig-group.ch/">
      <v-img src="https://www.geometre-valais.ch/wp-content/uploads/2015/03/logo-ig-group.png" width="180"/>
      </v-btn>
      <template v-if="itineraryViewReady">
        <POIs/>
        <Itinerary :coordinates="itineraryCoordinates" :itineraryInfos="itineraryInfos"/>
        <AccessLayer/>
        <Camera :initialPosition="initialPosition"/>
        <template v-if='!!stop'>
          <PositionTracker :coordinates="itineraryCoordinates" :start="start" :stop="stop"/>
          <Clock :maxStep="itineraryCoordinates.length" :start="start" :stop="stop"/>
        </template>
      </template>
      <NavigationControl/>
    </template>
  </div>
</template>

<script>
import * as Cesium from 'cesium/Cesium'
import 'cesium/Widgets/widgets.css'
import Camera from './Camera'
import PositionTracker from './PositionTracker'
import Itinerary from './Itinerary'
import NavigationControl from './NavigationControl'
import Clock from './Clock'
import POIs from './POIs'
import AccessLayer from './AccessLayer'
import { mapGetters } from 'vuex'
import { VIEWER_ACTION_ADD_LOADING_QUEUE, VIEWER_ACTION_REMOVE_LOADING_QUEUE, PLAYER_STORE_NAME, VIEWER_ACTION_INITIALIZE, PLAYER_STEP_GETTER, PLAYER_IS_PLAYING_GETTER, ITINERARY_STORE_NAME, ITINERARY_GETTER, ITINERARY_ACTION_SET_VIEWER, ITINERARY_GETTER_INFOS, VIEWER_STORE_NAME, VIEWER_GET_SELECTED_LAYER, initialSelectedLayer } from '@/store'
import { resetCamera, cameraInitialPosition, MIN_DETAIL_LEVEL, MAX_DETAIL_LEVEL, SCREEN_SPACE_ERROR } from '@/Utils'
import nameStyle from './SwissNameStyle'

// eslint-disable-next-line new-cap
const rectangle = new Cesium.Rectangle.fromDegrees(
  5.013926957923385,
  45.35600133779394,
  11.477436312994008,
  48.27502358353741
)

const getImageryProvider = (layer) => {
  // Swiss extent
  // eslint-disable-next-line new-cap
  return new Cesium.UrlTemplateImageryProvider({
    // Aerial image
    url: layer.url,
    minimumLevel: MIN_DETAIL_LEVEL,
    maximumLevel: MAX_DETAIL_LEVEL,
    tilingScheme: new Cesium.GeographicTilingScheme({
      numberOfLevelZeroTilesX: 2,
      numberOfLevelZeroTilesY: 1
    }),
    rectangle: rectangle
  })
}

let viewer

export default {
  data: () => ({
    viewerInitialized: false,
    initialPosition: cameraInitialPosition,
    start: Cesium.JulianDate.fromDate(new Date(2015, 2, 25, 16)),
    stop: null,
    clockReady: false,
    terrainErrorAppeared: false
  }),
  mounted () {
    this.$nextTick(async () => {
      // Set Cesium parameters
      viewer = await this.$store.dispatch(VIEWER_ACTION_INITIALIZE)
      this.viewerInitialized = true

      const ressourceTerrain = new Cesium.Resource({
        url:
          'https://3d.geo.admin.ch/ch.swisstopo.terrain.3d/v1/20201203/'
      })

      const terrainProvider = new Cesium.CesiumTerrainProvider({
        url: ressourceTerrain
      })
      // eslint-disable-next-line dot-notation
      const swissNameTileSet = new Cesium.Cesium3DTileset({
        url: 'https://vectortiles0.geo.admin.ch/3d-tiles/ch.swisstopo.swissnames3d.3d/20180716/tileset.json',
        maximumNumberOfLoadedTiles: 3,
        dynamicScreenSpaceError: true,
        dynamicScreenSpaceErrorDensity: 0.00278,
        dynamicScreenSpaceErrorFactor: 4.0,
        dynamicScreenSpaceErrorHeightFalloff: 0.25
      })
      swissNameTileSet.style = new Cesium.Cesium3DTileStyle(nameStyle)

      // Enable depth testing so things behind the terrain disappear.
      viewer.scene.globe.depthTestAgainstTerrain = true
      viewer.forceResize()
      viewer._cesiumWidget._creditContainer.style.display = 'none'
      viewer.scene.fxaa = true
      viewer.scene.globe.tileCacheSize = 1000
      viewer.scene.globe.maximumScreenSpaceError = SCREEN_SPACE_ERROR
      viewer.scene.postProcessStages.fxaa.enabled = true

      // FIXME TEMPORAIRE, REPOSE SUR LE FAIT QUE LE TERRAIN PROVIDER RENVOIE DES ERREURS SYSTEMATIQUEMENT
      this.$store.dispatch(VIEWER_ACTION_ADD_LOADING_QUEUE, 'viewer')

      setTimeout(() => {
        this.$store.dispatch(VIEWER_ACTION_REMOVE_LOADING_QUEUE, 'viewer')
        setTimeout(() => {
          viewer.scene.primitives.add(swissNameTileSet)
        }, 300)
      }, 8000)
      viewer.terrainProvider = terrainProvider
      viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK)

      this.setImageryLayer(getImageryProvider(initialSelectedLayer))

      this.$store.dispatch(ITINERARY_ACTION_SET_VIEWER, viewer)

      this.resetCamera(viewer, false)

      if (this.itineraryCoordinates) {
        this.initializeClockCamera(this.itineraryCoordinates)
      }
    })
  },
  methods: {
    resetCamera: resetCamera,
    initializeClockCamera (itinerary) {
      this.stop = Cesium.JulianDate.addSeconds(
        this.start,
        itinerary.length,
        new Cesium.JulianDate()
      )
      this.clockReady = true
      this.resetCamera(viewer, true)
    },
    setImageryLayer (layer, delayWait) {
      const layers = viewer.imageryLayers
      const baseLayer = layers.get(0)
      const imageryProvider = getImageryProvider(layer)
      layers.remove(baseLayer)
      layers.addImageryProvider(imageryProvider)
    }
  },
  computed: {
    ...mapGetters(PLAYER_STORE_NAME, {
      step: PLAYER_STEP_GETTER,
      playing: PLAYER_IS_PLAYING_GETTER
    }),
    ...mapGetters(ITINERARY_STORE_NAME, {
      itinerary: ITINERARY_GETTER,
      itineraryInfos: ITINERARY_GETTER_INFOS
    }),
    ...mapGetters(VIEWER_STORE_NAME, {
      selectedLayer: VIEWER_GET_SELECTED_LAYER
    }),
    devMode () {
      return process.env.NODE_ENV !== 'production'
    },
    itineraryCoordinates () {
      return this.itinerary ? this.itinerary.features[0].geometry.coordinates[0] : null
    },
    itineraryViewReady () {
      return this.itineraryCoordinates && this.clockReady
    }
  },
  watch: {
    itineraryCoordinates (itineraryVal) {
      if (itineraryVal) {
        this.initializeClockCamera(itineraryVal)
      }
    },
    selectedLayer (newLayer, oldLayer) {
      if (newLayer !== oldLayer) {
        this.setImageryLayer(newLayer, 2000)
      }
    }
  },
  components: { Camera: Camera, PositionTracker: PositionTracker, Itinerary: Itinerary, Clock: Clock, POIs: POIs, NavigationControl: NavigationControl, AccessLayer: AccessLayer }
}
</script>

<style lang="scss">
.logo {
  height: auto !important;
  position: fixed !important;
  z-index: 100 !important;
  top: 10px;
  left: 10px;
}

.cesium-viewer-infoBoxContainer {
  display: none
}
</style>
